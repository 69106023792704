export default function Header() {
    return (
      <div className="relative bg-gray-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <a href="#">
                  <span className="sr-only"></span>
                  <img
                    className="h-14 w-auto"
                    src={require("../Images/Logo.png")}
                    alt=""
                  />
                </a>
              </div>
            <div className="hidden md:flex md:space-x-10">
                <a href="#about" className=" px-8 text-base font-medium text-gray-500 hover:text-red-500">
                  About Us
                </a>
                <a href="#who" className="px-8 text-base font-medium text-gray-500 hover:text-red-500">
                  Who are we ?
                </a>
                <a href="#manage" className="px-8 text-base font-medium text-gray-500 hover:text-red-500">
                  Wealth Management Service
                </a> 
                <a href="#charity" className="px-8 text-base font-medium text-gray-500 hover:text-red-500">
                  Charity
                </a> 
                <a href="#contact" className="px-8 text-base font-medium text-gray-500 hover:text-red-500">
                  Contact
                </a> 
            </div>
            </div>
          </div>
        <hr className="border-color-grey-500"/>
        <main className="lg:relative">
          <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
            <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                <span className="block xl:inline">Altaï Partners</span>{' '}
                <span className="block text-red-500 xl:inline">Specialist in Alternative Investment Solutions</span>
              </h1>
              <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
              </div>
            </div>
          </div>
          <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
            <img
              className="absolute inset-0 w-full h-full object-cover"
              src={require("../Images/Header.png")}
              alt=""
            />
          </div>
        </main>
      </div>
    )
  }
  