import { LightBulbIcon, HomeIcon, LibraryIcon, UsersIcon, BriefcaseIcon, GlobeAltIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'Regulated',
    description: 'Regulated as a Financial Advisor and supervised by the French AMF (Autorité des Marchés Financiers).',
    icon: BriefcaseIcon,
  },
  {
    name: 'Global',
    description:
      'Altaï Partners also works in close co-operation with partners in many other jurisdictions.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Clients',
    description:
      'Our Clients include banks, management and investment companies, wealthy clients and family offices.',
    icon: UsersIcon,
  },
  {
    name: 'Independent',
    description:
      'Altaï Partners is an independent company, privately owned. We do not belong to any banking group and work exclusively in our clients’ interests. As we work with top custodian partners, we are able to get unbiased top-class services via our Partner institutions and Banks',
    icon: HomeIcon,
  }

]

export default function Who() {
    return (
      <div className="py-12 bg-gray-50" id="who">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl pb-4">
              Altaï Partners - Who are we ?
            </p>
          </div>
  
          <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              {features.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
                      <feature.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    )
  }